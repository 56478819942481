import { RouteInfo } from './sidebar.metadata';
export const ROUTES: RouteInfo[] = [

  {
    path: '/dashboard/main',
    title: 'Dashboard',
    iconType: 'material-icons-two-tone',
    icon: 'dashboard',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    submenu: [],
  },
  {
    path: '',
    title: 'Manage Products',
    iconType: 'material-icons-two-tone',
    icon: 'subtitles',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: 'badge bg-orange sidebar-badge float-end',
    submenu: [
      {
        path: '/manage-product/add',
        title: 'Add Product',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [],
      },
      {
        path: '/manage-product/pending-product',
        title: 'Pending Products',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [],
      },
      {
        path: '/manage-product/live-product',
        title: 'Live Products',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [],
      },
      {
        path: '/manage-product/all-product/2',
        title: 'All Products',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [],
      },

    ],
  },
  {
    path: '',
    title: 'Dessert Products',
    iconType: 'material-icons-two-tone',
    icon: 'subtitles',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: 'badge bg-orange sidebar-badge float-end',
    submenu: [
      {
        path: '/food-product/add',
        title: 'Add Product',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [],
      },
      {
        path: '/food-product/list',
        title: 'All Products',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [],
      },

    ],
  },
  {
    path: '/orders/list',
    title: 'Orders',
    iconType: 'material-icons-two-tone',
    icon: 'subtitles',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    submenu: [],
  },
  {
    path: '/transction/list',
    title: 'Transactions',
    iconType: 'material-icons-two-tone',
    icon: 'subtitles',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    submenu: [],
  },
  {
    path: '',
    title: 'Shipping',
    iconType: 'material-icons-two-tone',
    icon: 'subtitles',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: 'badge bg-orange sidebar-badge float-end',
    submenu: [
      {
        path: '/shiping/add-address',
        title: 'Address add',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [],
      },
      {
        path: '/shiping/list-address',
        title: 'Address List',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [],
      },
      {
        path: '/shiping/shipinglist',
        title: 'All Shipping List',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [],
      },
    ],
  },

];
